<template>
    <div class="flex-row">
        <h1>C&C Ventures LLC © 2024</h1>
    </div>
</template>

<script setup lang="ts">
    
</script>

<style scoped>
    div {
        height: 5vh;
        background-color: black;
    }

    h1 {
        font-size: 15px;
        margin-left: 15px;
        font-weight: lighter;
        color: white;
    }
</style>